<!--
 * @Description: 系统管理 日志管理 登录日志 systemLoginLogManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="登录用户">
              <el-input v-model="searchForm.logPerson"
                        size="mini"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="登录时间"
                          label-width="90px">
              <el-date-picker v-model="dateTimes"
                              type="daterange"
                              value-format="yyyyMMddHHmmss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>

    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>登录日志</span>
        <el-button type="text"
                   class="tableTitleButton">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  ref="tableRef"
                  :tableConfig='tableConfig'
                  @getList="getList"
                  :paginationConfig='paginationConfig'>
          <template slot="isFirst">
            <el-table-column label="序号"
                             width="100">
              <template slot-scope="scope">
                {{scope.$index+1 +(pageNum-1)*pageSize}}
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      //表格样式
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'logLevel',
          label: '日志等级',
        },
        {
          prop: 'logIp',
          label: 'IP地址',
        },
        {
          prop: 'logContent',
          label: '日志详情',
        },
        {
          prop: 'logTime',
          label: '登录时间',
        },
      ],
      tableList: { list: [] },
      //查询表单
      searchForm: {
        logPerson: '',//用户名
        startTime: '',//开始时间
        endTime: '',//结束时间
      },
      pageNum: 1,
      pageSize: 15,
      //时间查询条件
      dateTimes: [],
      index: '',//索引号
      //日期快速选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      Pager: null,//获取分页器
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    //获取分页器组件
    this.Pager = this.$refs.tableRef.$children[1]
    this.initialization()
  },
  //方法集合
  methods: {
    //初始化页面
    initialization () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$systemLoginLogManagement.queryLoginListByPage(this.searchForm).then((response) => {
        let list = response.resultEntity.list
        this.tableList.list = list
        this.paginationConfig.total = response.resultEntity.total
        this.listMap.pageNum = response.resultEntity.pageNum;

      })
    },

    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.pageSize = change.pageSize
      this.initialization()
    },

    //查询
    onSubmit () {
      if (this.dateTimes.length == 2) {
        this.searchForm.startTime = this.dateTimes[0]
        this.searchForm.endTime = this.dateTimes[1]
      }
      //设置分页器页面为1
      this.Pager.internalCurrentPage = 1
      // 设置序号为1
      this.listMap.pageNum = 1
      // 设置pageNum为1
      this.pageNum = 1
      this.initialization()
    },

    // 清空
    resetForm () {
      this.searchForm = {}
      this.dateTimes = []
      this.initialization()
      // 设置pageNum为1
      this.listMap.pageNum = 1
      // 设置序号为1
      this.pageNum = 1
      //设置分页器页面为1
      this.Pager.internalCurrentPage = 1
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainbody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    min-height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .el-button--text {
    background: #00c9d6;
    height: 32px;
    width: 60px;
    color: white;
    border-radius: 0;
    font-size: 14px;
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>
